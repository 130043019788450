import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import app from "../base";

const db = app.database();

const AboutWrapper = styled.div`
    width: 100%;
    padding: 40px 0;
`

const AboutContent = styled.div`
    max-width: 500px;
    margin: 0 auto;
    padding: 0 10px;
    white-space: pre-wrap;
`

const AboutTitle = styled.p`
    font-size: 30px;
    font-weight: bold;
    text-align: center;
`

export default function About({category}) {
    const [jeppisJazzAbout, setJeppisJazzAbout] = useState();
    const [thursdayJazzAbout, setThursdayJazzAbout] = useState();
    const  [jakobBigBandAbout, setJakobBigBandAbout] = useState();
    const  [jeppisJazzFestivalAbout, setJeppisJazzFestivalAbout] = useState();
    
    useEffect(() => {
        var jeppisJazzAboutArray;
        const jeppisJazzAboutRef = db.ref("content/jeppisJazzAbout");

        jeppisJazzAboutRef.on("value", (snapshot) => {
            const jeppisJazzAbouts = snapshot.val();
            const jeppisJazzAboutList = [];

            for (let id in jeppisJazzAbouts) {
                jeppisJazzAboutList.push({ id, ...jeppisJazzAbouts[id] });
            }

            jeppisJazzAboutArray = jeppisJazzAboutList;

            setJeppisJazzAbout(jeppisJazzAboutArray[jeppisJazzAboutArray.length - 1]);
        });

        var thursdayJazzAboutArray;
        const thursdayJazzAboutRef = db.ref("content/thursdayJazzAbout");

        thursdayJazzAboutRef.on("value", (snapshot) => {
            const thursdayJazzAbouts = snapshot.val();
            const thursdayJazzAboutList = [];

            for (let id in thursdayJazzAbouts) {
                thursdayJazzAboutList.push({ id, ...thursdayJazzAbouts[id] });
            }

            thursdayJazzAboutArray = thursdayJazzAboutList;

            setThursdayJazzAbout(thursdayJazzAboutArray[thursdayJazzAboutArray.length - 1]);
        });

        var jakobBigBandAboutArray;
        const jakobBigBandAboutRef = db.ref("content/jakobBigBandAbout");

        jakobBigBandAboutRef.on("value", (snapshot) => {
            const jakobBigBandAbouts = snapshot.val();
            const jakobBigBandAboutList = [];

            for (let id in jakobBigBandAbouts) {
                jakobBigBandAboutList.push({ id, ...jakobBigBandAbouts[id] });
            }

            jakobBigBandAboutArray = jakobBigBandAboutList;

            setJakobBigBandAbout(jakobBigBandAboutArray[jakobBigBandAboutArray.length - 1]);
        });

        var jeppisJazzFestivalAboutArray;
        const jeppisJazzFestivalAboutRef = db.ref("content/jeppisJazzFestivalAbout");

        jeppisJazzFestivalAboutRef.on("value", (snapshot) => {
            const jeppisJazzFestivalAbouts = snapshot.val();
            const jeppisJazzFestivalAboutList = [];

            for (let id in jeppisJazzFestivalAbouts) {
                jeppisJazzFestivalAboutList.push({ id, ...jeppisJazzFestivalAbouts[id] });
            }

            jeppisJazzFestivalAboutArray = jeppisJazzFestivalAboutList;

            setJeppisJazzFestivalAbout(jeppisJazzFestivalAboutArray[jeppisJazzFestivalAboutArray.length - 1]);
        });
    }, [])
    return (
        <AboutWrapper>
            {(category === "Thursday Jazz" || category === "Concert") && <AboutContent>
                <AboutTitle id="about">About</AboutTitle>
               {thursdayJazzAbout && thursdayJazzAbout.text}
            </AboutContent>}

            {category === "Jakob Big Band" && <AboutContent>
                <AboutTitle id="about">About</AboutTitle>
                {jakobBigBandAbout && jakobBigBandAbout.text}
            </AboutContent>}

            {category === "Jeppis Jazz Festival" && <AboutContent>
                <AboutTitle id="about">About</AboutTitle>
                {jeppisJazzFestivalAbout && jeppisJazzFestivalAbout.text}
            </AboutContent>}

            {!category && <AboutContent>
                <AboutTitle id="about">About</AboutTitle>
                 {jeppisJazzAbout && jeppisJazzAbout.text}
                 </AboutContent>}
            
        </AboutWrapper>
    )
}
