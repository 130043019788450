import React, { useEffect, useState, useContext } from "react";
import { filterPosts } from "../utils";
import styled from 'styled-components';

import app from "../base";
import EventSmallCard from "../Components/EventSmallCard";
import NextEventCard from "../Components/NextEventCard";
import Header from "../Components/Header";
import Contact from "../Components/Contact";
import InstagramPhoto from "../Components/InstagramPhoto";
import Member from "../pages/Member";

import logo from "../Components/jeppisJazzLogo.png";
import useScript from "../hooks/useScript";
import About from "../Components/About";
import { AuthContext } from "../Auth";

const db = app.database();

const NextEventWrapper = styled.div`
    display: flex;
    justify-content: center;
    @media (max-width: 900px) {
        flex-direction: column;
        align-items: center;
    }
    `

export default function Home() {
    const [posts, setPosts] = useState();
    const [postList, setPostList] = useState();
    const [about, setAbout] = useState();
    const { setCurrentPageHandler, currentPage } = useContext(AuthContext);

    const chooseClassName = (category) => {
        if (category === "Jeppis Jazz Festival") return "jeppisJazzFestivalBg";
        if (category === "Jakob Big Band") return "jakobBigBandBg";
        if (category === "Thursday Jazz") return "thursdayJazzBg";
    };

    const onMouseOverHandler = () => {
        setCurrentPageHandler(currentPage + 1)
    }

    useEffect(() => {
        var postsArray;
        const postsRef = db.ref("posts");

        postsRef.on("value", (snapshot) => {
            const posts = snapshot.val();
            const postList = [];

            for (let id in posts) {
                postList.push({ id, ...posts[id] });
            }

            postsArray = postList;

            const filteredPosts = filterPosts(undefined, postsArray);

            setPostList(filteredPosts);
        });

        return postsArray;
    }, []);

    useScript("https://www.instagram.com/embed.js");

    

    return (
        <>
                <Header />

            <div onMouseEnter={onMouseOverHandler} key={Math.random()} className="events">
                <div className="categoryBanner jeppisJazzBanner jeppisJazzBannerBg">
                    <img src={logo}></img>
                </div>

                <div id="events" className="nextEvent">
                    <NextEventWrapper>
                    {postList ? (
                        <>
                        <div>
                        <h3 className="nextEventTitle">Next Event</h3>
                        <NextEventCard
                            category={postList[0].category}
                            image={postList[0].fileUrl}
                            date={postList[0].date}
                            time={postList[0].time}
                            place={postList[0].place}
                            title={postList[0].title}
                            id={postList[0].id}
                        />
                        </div>
                        </>
                    ) : (
                        ""
                    )}
                        
                        <InstagramPhoto />
                    
                    </NextEventWrapper>
                </div>

                <div className="upComingEvents">
                    <h3 className="upComingEventsTitle">Upcoming Events</h3>
                    <div className="eventSmallCardContainer">
                        {postList
                            ? postList
                                  .slice(1)
                                  .map((post, index) => (
                                      <EventSmallCard
                                          category={post.category}
                                          image={post.fileUrl}
                                          date={post.date}
                                          time={post.time}
                                          place={post.place}
                                          title={post.title}
                                          description={post.description}
                                          address={post.address}
                                          price={post.price}
                                          mediaLink={post.mediaLink}
                                          createdBy={post.createdBy}
                                          id={post.id}
                                          key={index}
                                      />
                                  ))
                            : ""}
                    </div>
                </div>
            </div>
            <About/>

            <Contact category={undefined} />
        </>
    );
}
