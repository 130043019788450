import React from "react";

export const filterPosts = (category, postsArray) => {
    const filteredPosts = [];

    var date = new Date();
    var currentDateAndTime = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 16);

    if (category) {
        if(category === "Thursday Jazz"){
            postsArray.forEach((post) => {
                if ((post.category === "Thursday Jazz" || post.category === "Concert") && `${post.date}T${post.time}` >= currentDateAndTime) {
                    filteredPosts.push(post);
                }
            })

        } else {
        postsArray.forEach((post) => {
            if (post.category === category && `${post.date}T${post.time}` >= currentDateAndTime) {
                filteredPosts.push(post);
            }
        });
    }
    } else {
        postsArray.forEach((post) => {
            if (`${post.date}T${post.time}` >= currentDateAndTime) {
                filteredPosts.push(post);
            }
        });
    }

    return filteredPosts.sort((a, b) => (a.date + a.time > b.date + b.time ? 1 : -1));
};

export const chooseClassName = (category) => {
    if (category === "Jeppis Jazz Festival") return "jeppisJazzFestivalBg";
    if (category === "Jakob Big Band") return "jakobBigBandBg";
    if (category === "Thursday Jazz") return "thursdayJazzBg";
    if (category === "Concert") return "concertBg";
};

export const chooseColor = (category) => {
    if (category === "Jeppis Jazz Festival") return "jeppisJazzFestivalColor";
    if (category === "Jakob Big Band") return "jakobBigBandColor";
    if (category === "Thursday Jazz" || category === "Concert") return "thursdayJazzColor";
}

export const formatDate = (date) => {
    return date.split("-").reverse().join(".");
};

export const filterPastPosts = (category, postsArray) => {
    const filteredPosts = [];

    var date = new Date();
    var currentDateAndTime = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 16);

    if (category) {
        if(category === "Thursday Jazz"){
            postsArray.forEach((post) => {
                if ((post.category === "Thursday Jazz" || post.category === "Concert") && `${post.date}T${post.time}` <= currentDateAndTime) {
                    filteredPosts.push(post);
                }
            })

        } else {
        postsArray.forEach((post) => {
            if (post.category === category && `${post.date}T${post.time}` <= currentDateAndTime) {
                filteredPosts.push(post);
            }
        });
    }
    } else {
        postsArray.forEach((post) => {
            if (`${post.date}T${post.time}` <= currentDateAndTime) {
                filteredPosts.push(post);
            }
        });
    }

    return filteredPosts.sort((a, b) => (a.date + a.time < b.date + b.time ? 1 : -1));
};
