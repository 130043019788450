import React, {useEffect, useState} from "react";
import app from "../base";

const db = app.database();

export default function Contact({ category }) {
    const [thursdayJazzContact, setThursdayJazzContact] = useState();
    const [JeppisJazzFestivalContact, setJeppisJazzFestivalContact] = useState();
    const [jakobBigBandContact, setJakobBigBandContact] = useState();
    const [concertsContact, setConcertsContact] = useState();

    const chooseContactBg = (category) => {
        if (category === "Jeppis Jazz Festival") return "jeppisJazzFestivalContact";
        if (category === "Jakob Big Band") return "jakobBigBandContact";
        if (category === "Thursday Jazz") return "thursdayJazzContact";
        else return "homeContact";
    };

    useEffect(() => {
        var thursdayJazzContactArray;
        const thursdayJazzContactRef = db.ref("content/thursdayJazzContact");

        thursdayJazzContactRef.on("value", (snapshot) => {
            const thursdayJazzContacts = snapshot.val();
            const thursdayJazzContactList = [];

            for (let id in thursdayJazzContacts) {
                thursdayJazzContactList.push({ id, ...thursdayJazzContacts[id] });
            }

            thursdayJazzContactArray = thursdayJazzContactList;

            setThursdayJazzContact(thursdayJazzContactArray[thursdayJazzContactArray.length - 1]);
        });

        var jeppisJazzFestivalContactArray;
        const jeppisJazzFestivalContactRef = db.ref("content/jeppisJazzFestivalContact");

        jeppisJazzFestivalContactRef.on("value", (snapshot) => {
            const jeppisJazzFestivalContacts = snapshot.val();
            const jeppisJazzFestivalContactList = [];

            for (let id in jeppisJazzFestivalContacts) {
                jeppisJazzFestivalContactList.push({ id, ...jeppisJazzFestivalContacts[id] });
            }

            jeppisJazzFestivalContactArray = jeppisJazzFestivalContactList;

            setJeppisJazzFestivalContact(jeppisJazzFestivalContactArray[jeppisJazzFestivalContactArray.length - 1]);
        });

        var jakobBigBandContactArray;
        const jakobBigBandContactRef = db.ref("content/jakobBigBandContact");

        jakobBigBandContactRef.on("value", (snapshot) => {
            const jakobBigBandContacts = snapshot.val();
            const jakobBigBandContactList = [];

            for (let id in jakobBigBandContacts) {
                jakobBigBandContactList.push({ id, ...jakobBigBandContacts[id] });
            }

            jakobBigBandContactArray = jakobBigBandContactList;

            setJakobBigBandContact(jakobBigBandContactArray[jakobBigBandContactArray.length - 1]);
        });

        var concertsContactArray;
        const concertsContactRef = db.ref("content/concertsContact");

        concertsContactRef.on("value", (snapshot) => {
            const concertsContacts = snapshot.val();
            const concertsContactList = [];

            for (let id in concertsContacts) {
                concertsContactList.push({ id, ...concertsContacts[id] });
            }

            concertsContactArray = concertsContactList;

            setConcertsContact(concertsContactArray[concertsContactArray.length - 1]);
        });

        
    }, [])
    return (
        <div id="contact" className={`contact ${chooseContactBg(category)}`}>
            <div className="contactWrapper">
                <div className="someWrapper">
                    <p className="contactTitle">Contact</p>
                    <a
                        style={{ color: "white", marginRight: "10px", fontSize: "2rem" }}
                        href="https://www.facebook.com/JeppisJazz"
                        target="_blank"
                        rel="noreferrer">
                        <i className="fab fa-facebook"></i>
                    </a>
                    <a
                        style={{ color: "white", marginLeft: "10px", fontSize: "2rem" }}
                        href="https://www.instagram.com/jeppisjazz/"
                        target="_blank"
                        rel="noreferrer">
                        <i className="fab fa-instagram"></i>
                    </a>
                </div>
                <div className="contactConcerts">
                    <p className="title">Concerts</p>
                    <div className="contactGroup">
                        <p className="name">{concertsContact && concertsContact.name}</p>
                        <p>{concertsContact && concertsContact.role}</p>
                        <p className="email">{concertsContact && concertsContact.email}</p>
                    </div>
                </div>
                <div className="contactThursdayJazz">
                    <p className="title">Thursday Jazz</p>
                    <div className="contactGroup">
                        <p className="name">{thursdayJazzContact && thursdayJazzContact.name}</p>
                        <p>{thursdayJazzContact && thursdayJazzContact.role}</p>
                        <p className="email">{thursdayJazzContact && thursdayJazzContact.email}</p>
                    </div>

                    <div className="someGroup"></div>
                </div>
                <div className="contactJeppisJazzFestival">
                    <p className="title">Jeppis Jazz Festival</p>
                    <div className="contactGroup">
                        <div className="contactGroup">
                            <p className="name">{JeppisJazzFestivalContact && JeppisJazzFestivalContact.name}</p>
                            <p>{JeppisJazzFestivalContact && JeppisJazzFestivalContact.role}</p>

                            <p className="email">{JeppisJazzFestivalContact && JeppisJazzFestivalContact.email}</p>
                        </div>
                    </div>
                    <div className="someGroup"></div>
                </div>
                <div className="contactJakobBigBand">
                    <p className="title">Jakob Big Band</p>
                    <div className="contactGroup">
                        <p className="name">{jakobBigBandContact && jakobBigBandContact.name}</p>
                        <p>{jakobBigBandContact && jakobBigBandContact.role}</p>
                        <p className="email">{jakobBigBandContact && jakobBigBandContact.email}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
