import React, { useState, useEffect, useContext } from "react";
import Header from "../Components/Header";
import emailjs from "emailjs-com";
import styled from "styled-components";
import { AuthContext } from "../Auth";
import app from "../base";

const db = app.database();

const MemberTitle = styled.div`
    font-size: 30px;
`;

const MemberInfo = styled.p`
max-width: 320px;
white-space: pre-wrap;
`

const LanguageChoiceWrapper = styled.div`
    width: 100%;
    font-size: 1.5rem;
    text-align: right;
    letter-spacing: 2px;
    margin-bottom: 5px;
    cursor: pointer;
`;

const EmailSentWrapper = styled.div`
    width: 100%;
    text-align: center;
    font-weight: bold;
`;

const Text = styled.span``;

export default function Member() {
    const [member, setMember] = useState();
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [language, setLanguage] = useState("sv");
    const [memberInfoSe, setMemberInfoSe] = useState();
    const [memberInfoFi, setMemberInfoFi] = useState();
    const [memberInfoEn, setMemberInfoEn] = useState();

    const onChangeHandler = (e) => {
        setMember({ ...member, [e.target.name]: e.target.value });
    };

    const { setCurrentPageHandler, currentPage } = useContext(AuthContext);

    const onMouseOverHandler = () => {
        setCurrentPageHandler(currentPage + 1);
    };

    const chooseLanguage = (language) => {
        setLanguage(language);
    };

    const submitHandler = (e) => {
        e.preventDefault();

        let errorMessage = "Please fill in";
        let error = false;

        if (member.name.length === 0) {
            errorMessage += " name";
            error = true;
        }
        if (member.streetAddress.length === 0) {
            errorMessage += " address";
            error = true;
        }
        if (member.postCode.length === 0) {
            errorMessage += " post code";
            error = true;
        }
        if (member.postOffice.length === 0) {
            errorMessage += " post office";
            error = true;
        }
        if (member.phoneNumber.length === 0) {
            errorMessage += " phone number";
            error = true;
        }
        if (member.email.length === 0) {
            errorMessage += " email";
            error = true;
        }
        if (member.student.length === 0) {
            errorMessage += " student";
            error = true;
        }
        if (error) {
            alert(errorMessage);
            return;
        }

        emailjs
            .sendForm(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                e.target,
                process.env.REACT_APP_EMAILJS_USER_ID
            )
            .then((response) => console.log(response))
            .catch((e) => console.log(e));

        setIsEmailSent(true);
    };

    useEffect(() => {
        var memberInfoSeArray;
        const memberInfoSeRef = db.ref("content/memberInfoSe");

        memberInfoSeRef.on("value", (snapshot) => {
            const memberInfoSes = snapshot.val();
            const memberInfoSeList = [];

            for (let id in memberInfoSes) {
                memberInfoSeList.push({ id, ...memberInfoSes[id] });
            }

            memberInfoSeArray = memberInfoSeList;

            setMemberInfoSe(memberInfoSeArray[memberInfoSeArray.length - 1]);
        });

        var memberInfoFiArray;
        const memberInfoFiRef = db.ref("content/memberInfoFi");

        memberInfoFiRef.on("value", (snapshot) => {
            const memberInfoFis = snapshot.val();
            const memberInfoFiList = [];

            for (let id in memberInfoFis) {
                memberInfoFiList.push({ id, ...memberInfoFis[id] });
            }

            memberInfoFiArray = memberInfoFiList;

            setMemberInfoFi(memberInfoFiArray[memberInfoFiArray.length - 1]);
        });

        var memberInfoEnArray;
        const memberInfoEnRef = db.ref("content/memberInfoEn");

        memberInfoEnRef.on("value", (snapshot) => {
            const memberInfoEns = snapshot.val();
            const memberInfoEnList = [];

            for (let id in memberInfoEns) {
                memberInfoEnList.push({ id, ...memberInfoEns[id] });
            }

            memberInfoEnArray = memberInfoEnList;

            setMemberInfoEn(memberInfoEnArray[memberInfoEnArray.length - 1]);
        });
    }, [])

    return (
        <>
            <Header />
            <div onMouseEnter={onMouseOverHandler} className="membershipFormContainer memberFormBg">
                <form className="membershipForm" onSubmit={submitHandler}>
                    <LanguageChoiceWrapper>
                        <Text onClick={() => chooseLanguage("sv")}>Sv</Text> |{" "}
                        <Text onClick={() => chooseLanguage("fi")}>Fi</Text> |{" "}
                        <Text onClick={() => chooseLanguage("en")}>En</Text>{" "}
                    </LanguageChoiceWrapper>
                    {language === "sv" && (
                        <>
                            <MemberTitle>Bli medlem</MemberTitle>

                            <MemberInfo style={{ maxWidth: "320px" }}>
                                {memberInfoSe && memberInfoSe.text}
                                <br></br>
                                <br></br>
                            </MemberInfo>
                        </>
                    )}

                    {language === "fi" && (
                        <>
                            <MemberInfo style={{ maxWidth: "320px" }}>
                                <MemberTitle>Liity jäseneksi</MemberTitle>
                                {memberInfoFi && memberInfoFi.text}
                                <br></br>
                                <br></br>
                            </MemberInfo>
                        </>
                    )}

                    {language === "en" && (
                        <>
                            <MemberInfo style={{ maxWidth: "320px" }}>
                                <MemberTitle>Become a member</MemberTitle>
                                {memberInfoEn && memberInfoEn.text}
                                <br></br>
                                <br></br>
                            </MemberInfo>
                        </>
                    )}

                    {!isEmailSent && (
                        <>
                            <div className="inputGroup">
                                <label for="name">
                                    {language === "sv" && <Text>Namn *</Text>}
                                    {language === "fi" && <Text>Nimi *</Text>}
                                    {language === "en" && <Text>Name *</Text>}
                                </label>
                                <input
                                    type="text"
                                    onChange={onChangeHandler}
                                    id="name"
                                    name="name"></input>
                            </div>
                            <div className="inputGroup">
                                <label for="streetAddress">
                                    {language === "sv" && <Text>Adress *</Text>}
                                    {language === "fi" && <Text>Osoite *</Text>}
                                    {language === "en" && <Text>Address *</Text>}
                                </label>
                                <input
                                    type="text"
                                    onChange={onChangeHandler}
                                    id="streetAddress"
                                    name="streetAddress"></input>
                            </div>
                            <div className="inputGroup">
                                <label for="postCode">
                                    {language === "sv" && <Text>Postnummer *</Text>}
                                    {language === "fi" && <Text>Postinumero *</Text>}
                                    {language === "en" && <Text>Postal code *</Text>}
                                </label>
                                <input
                                    type="text"
                                    onChange={onChangeHandler}
                                    id="postCode"
                                    name="postCode"></input>
                            </div>
                            <div className="inputGroup">
                                <label for="postOffice">
                                    {language === "sv" && <Text>Postanstalt *</Text>}
                                    {language === "fi" && <Text>Postitoimipaikka *</Text>}
                                    {language === "en" && <Text>Post office *</Text>}
                                </label>
                                <input
                                    type="text"
                                    onChange={onChangeHandler}
                                    id="postOffice"
                                    name="postOffice"></input>
                            </div>
                            <div className="inputGroup">
                                <label for="phoneNumber">
                                    {language === "sv" && <Text>Telefonnummer *</Text>}
                                    {language === "fi" && <Text>Puhelinnumero *</Text>}
                                    {language === "en" && <Text>Phone number *</Text>}
                                </label>
                                <input
                                    type="text"
                                    onChange={onChangeHandler}
                                    id="phoneNumber"
                                    name="phoneNumber"></input>
                            </div>
                            <div className="inputGroup">
                                <label for="email">
                                    {language === "sv" && <Text>Epost *</Text>}
                                    {language === "fi" && <Text>Sähköposti *</Text>}
                                    {language === "en" && <Text>Email *</Text>}
                                </label>
                                <input
                                    type="email"
                                    onChange={onChangeHandler}
                                    id="email"
                                    name="email"></input>
                            </div>
                            <div className="inputGroup">
                                <label for="student">
                                    {language === "sv" && <Text>Studerande *</Text>}
                                    {language === "fi" && <Text>Opiskelija *</Text>}
                                    {language === "en" && <Text>Student *</Text>}
                                </label>

                                <div className="radioBtnWrapper">
                                    <input
                                        onChange={onChangeHandler}
                                        type="radio"
                                        id="student"
                                        name="student"
                                        value="Ja"></input>
                                    <label for="student">
                                        {language === "sv" && <Text>Ja</Text>}
                                        {language === "fi" && <Text>Kyllä</Text>}
                                        {language === "en" && <Text>Yes</Text>}
                                        
                                    </label>
                                    <br></br>
                                    <input
                                        onChange={onChangeHandler}
                                        type="radio"
                                        id="student"
                                        name="student"
                                        value="Nej"></input>
                                    <label for="student">
                                        {language === "sv" && <Text>Nej</Text>}
                                        {language === "fi" && <Text>Ei</Text>}
                                        {language === "en" && <Text>No</Text>}
                                    </label>
                                </div>
                            </div>
                            <button type="submit">
                                {language === "sv" && <Text>Skicka</Text>}
                                {language === "fi" && <Text>Lähetä</Text>}
                                {language === "en" && <Text>Send</Text>}
                            </button>
                        </>
                    )}
                    {isEmailSent && (
                        <div>
                            <br></br>
                            <br></br>
                            <EmailSentWrapper>
                                {language === "sv" && <Text>Din begäran har nu skickats.</Text>}
                                {language === "fi" && <Text>Viestisi on lähetetty.</Text>}
                                {language === "en" && <Text>Your request has been sent.</Text>}
                            </EmailSentWrapper>
                        </div>
                    )}
                </form>
            </div>
            {/* <Contact /> */}
        </>
    );
}
