import React from 'react'
import { useHistory } from 'react-router-dom';
import { chooseClassName, formatDate } from '../utils';

export default function NextEventCard(props) {
    const history = useHistory();

    const handleClick = () => {
        let mappedCategory;
        if(props.category === "Jakob Big Band") { mappedCategory = "jakobbigband" }
        if(props.category === "Jeppis Jazz Festival") { mappedCategory = "jeppisjazzfestival" }
        if(props.category === "Thursday Jazz") { mappedCategory = "thursdayjazz" }
        if(props.category === "Concert") { mappedCategory = "concert"}
        history.push(`/${mappedCategory}/${props.id}`);
    }

    return (
        <div className="nextEventCard">
            <div className="nextEventCardImage">
                <img className="nextEventImg" src={props.image} />
                <p className={`imgCategory ${chooseClassName(props.category)}`}>{props.category}</p>
            </div>
            <div className="nextEventCardText">
                <p className="nextEventCardDateAndTime">{formatDate(props.date)} </p>
                <p>kl. {props.time}</p>
                <p className="nextEventCardPlace">{props.place.substring(0, 35)}{props.place.length > 35 && "..."}</p>
                <h3 className="nextEventCardTitle">{props.title.substring(0, 35)}{props.title.length > 35 && "..."}</h3>
                <button onClick={handleClick} className="nextEventCardButton">Info</button>
            </div>
        </div>
    )
}
