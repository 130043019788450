import React, { useRef, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Link as LinkScroll, animateScroll as scroll } from "react-scroll";

import thursdayJazzLogo from "../Components/thursdayJazzLogo.png";
import jeppisJazzFestivalLogoBlue from "../Components/jeppisJazzFestivalLogoBlue.png";
import jakobBigBandLogo from "../Components/JBBLogo.jpg";
import jeppisJazzLogo from "../Components/jeppisJazzLogo.png";
import { HashLink } from "react-router-hash-link";
import styled from "styled-components";

const FestivalDateWrapper = styled.span`
    position: absolute;
    top: 43px;
    font-size: 0.7rem;
    text-transform: capitalize;
`;

export default function MobileHeader({ jeppisJazzFestivalDate }) {
    const navRef = useRef();
    const navLinkRef = useRef();
    const [isJeppisJazz, setIsJeppisJazz] = useState();
    const [isThursdayJazz, setIsThursdayJazz] = useState(false);
    const [isJeppisJazzFestival, setIsJeppisJazzFestival] = useState(false);
    const [isJakobBigBand, setIsJakobBigBand] = useState(false);
    const [isJeppisJazzClicked, setisJeppisJazzClicked] = useState();
    const [isThursdayJazzClicked, setIsThursdayJazzClicked] = useState();
    const [isJakobBigBandClicked, setIsJakobBigBandClicked] = useState();
    const [currentUrl, setCurrentUrl] = useState();

    const history = useHistory();

    const navigateToHome = () => {
        history.push("/");
    };

    const chooseLogo = () => {
        if (window.location.href.includes("jakobbigband")) return jakobBigBandLogo;
        else if (window.location.href.includes("jeppisjazzfestival"))
            return jeppisJazzFestivalLogoBlue;
        else if (window.location.href.includes("thursdayjazz")) return thursdayJazzLogo;
        else return jeppisJazzLogo;
    };

    useEffect(() => {
        setCurrentUrl(window.location.href);
    }, []);

    useEffect(() => {
        if (window.location.href.includes("jakobbigband")) {
            setIsJakobBigBand(true);
            setIsJeppisJazzFestival(false);
            setIsThursdayJazz(false);
            setIsJeppisJazz(false);
            setisJeppisJazzClicked(false);
            setIsThursdayJazzClicked(false);
            setIsJakobBigBandClicked(true);
        } else if (window.location.href.includes("jeppisjazzfestival")) {
            setIsJakobBigBand(false);
            setIsJeppisJazzFestival(true);
            setIsThursdayJazz(false);
            setIsJeppisJazz(false);
            setisJeppisJazzClicked(true);
            setIsThursdayJazzClicked(false);
            setIsJakobBigBandClicked(false);
        } else if (
            window.location.href.includes("thursdayjazz") ||
            window.location.href.includes("backline")
        ) {
            setIsJakobBigBand(false);
            setIsJeppisJazzFestival(false);
            setIsThursdayJazz(true);
            setIsJeppisJazz(false);
            setisJeppisJazzClicked(false);
            setIsThursdayJazzClicked(true);
            setIsJakobBigBandClicked(false);
        } else if (
            window.location.href.includes("admin") ||
            window.location.href.includes("login") ||
            window.location.href.includes("addphoto")
        ) {
            setIsJakobBigBand(false);
            setIsJeppisJazzFestival(false);
            setIsThursdayJazz(false);
            setIsJeppisJazz(false);
        } else if (window.location.href.includes("member")) {
            setIsJakobBigBand(false);
            setIsJeppisJazzFestival(false);
            setIsThursdayJazz(false);
            setIsJeppisJazz(true);
        } else {
            setIsJakobBigBand(false);
            setIsJeppisJazzFestival(false);
            setIsThursdayJazz(false);
            setIsJeppisJazz(true);
        }
    }, [currentUrl]);

    const handleMenuClick = () => {
        document.body.classList.toggle("nav-open");
    };

    const handleNavLinkClick = (e) => {
        scroll.scrollToTop({ duration: 0 });
        document.body.classList.remove("nav-open");
        if (e.target.href.includes("jakobbigband")) {
            setIsJakobBigBand(true);
            setIsJeppisJazzFestival(false);
            setIsThursdayJazz(false);
            setIsJeppisJazz(false);
            setisJeppisJazzClicked(false);
            setIsThursdayJazzClicked(false);
            setIsJakobBigBandClicked(true);
        } else if (e.target.href.includes("jeppisjazzfestival")) {
            setIsJakobBigBand(false);
            setIsJeppisJazzFestival(true);
            setIsThursdayJazz(false);
            setIsJeppisJazz(false);
            setisJeppisJazzClicked(true);
            setIsThursdayJazzClicked(false);
            setIsJakobBigBandClicked(false);
        } else if (e.target.href.includes("thursdayjazz")) {
            setIsJakobBigBand(false);
            setIsJeppisJazzFestival(false);
            setIsThursdayJazz(true);
            setIsJeppisJazz(false);
            setisJeppisJazzClicked(false);
            setIsThursdayJazzClicked(true);
            setIsJakobBigBandClicked(false);
        } else {
            setIsJakobBigBand(false);
            setIsJeppisJazzFestival(false);
            setIsThursdayJazz(false);
            setIsJeppisJazz(true);
        }
    };

    const scrollToTop = () => {
        scroll.scrollToTop({ duration: 200 });
    };

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -155; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    return (
        <>
            <header className="mobileHeader">
                <div className="logo">
                    <img onClick={scrollToTop} src={chooseLogo()} />
                </div>
                <button
                    ref={navRef}
                    onClick={handleMenuClick}
                    className="nav-toggle"
                    aria-label="toggle navigation">
                    <span className="hamburger"></span>
                </button>
                <nav className="nav">
                    <ul className="nav__list">
                        <li ref={navLinkRef} onClick={handleNavLinkClick} className="nav__item">
                            <a
                                className={`mobileNavItem ${
                                    isJeppisJazz ? "jeppisJazzHeader-active" : "jeppisJazzHeader"
                                }`}
                                href="/">
                                Home
                            </a>
                        </li>
                        <li ref={navLinkRef} onClick={handleNavLinkClick} className="nav__item">
                            <Link
                                className={`mobileNavItem ${
                                    isThursdayJazz
                                        ? "thursdayJazzHeader-active"
                                        : "thursdayJazzHeader"
                                }`}
                                to="/thursdayjazz">
                                Club
                            </Link>
                        </li>
                        <li ref={navLinkRef} onClick={handleNavLinkClick} className="nav__item">
                            <Link
                                className={`mobileNavItem ${
                                    isJeppisJazzFestival
                                        ? "jeppisJazzFestivalHeader-active"
                                        : "jeppisJazzFestivalHeader"
                                }`}
                                to="/jeppisjazzfestival">
                                    Festival
                                <FestivalDateWrapper>
                                    {jeppisJazzFestivalDate && jeppisJazzFestivalDate.text}
                                </FestivalDateWrapper>
                            </Link>
                        </li>
                        <li ref={navLinkRef} onClick={handleNavLinkClick} className="nav__item">
                            <Link
                                className={`mobileNavItem ${
                                    isJakobBigBand
                                        ? "jakobBigBandHeader-active"
                                        : "jakobBigBandHeader"
                                }`}
                                to="/jakobbigband">
                                Jakob Big Band
                            </Link>
                        </li>
                    </ul>
                </nav>
            </header>
            <div className="mobileSubHeaderWrapper">
                {isThursdayJazz && (
                    <ul className="mobileSubHeader thursdayJazzBg">
                        <li>
                            <HashLink to="/thursdayjazz#about" scroll={(el) => scrollWithOffset(el)}>About</HashLink>
                        </li>
                        <li>
                            <HashLink to="/thursdayjazz#contact" scroll={(el) => scrollWithOffset(el)}>Contact</HashLink>
                        </li>
                        <li>
                            <Link onClick={handleNavLinkClick} to="/backline">
                                Backline
                            </Link>
                        </li>
                    </ul>
                )}
                {isJeppisJazzFestival && (
                    <ul className="mobileSubHeader jeppisJazzFestivalBg">
                        <li>
                            <HashLink to="/jeppisjazzfestival#about" scroll={(el) => scrollWithOffset(el)}>About</HashLink>
                        </li>
                        <li>
                            <HashLink to="/jeppisjazzfestival#contact" scroll={(el) => scrollWithOffset(el)}>Contact</HashLink>
                        </li>
                        <li>
                            <HashLink to="/jeppisjazzfestival#gallery" scroll={(el) => scrollWithOffset(el)}>Gallery</HashLink>
                        </li>
                    </ul>
                )}
                {isJakobBigBand && (
                    <ul className="mobileSubHeader jakobBigBandBg">
                        <li>
                            <HashLink to="/jakobbigband#about" scroll={(el) => scrollWithOffset(el)}>About</HashLink>
                        </li>
                        <li>
                            <HashLink to="/jakobbigband#contact" scroll={(el) => scrollWithOffset(el)}>Contact</HashLink>
                        </li>
                    </ul>
                )}
                {isJeppisJazz && (
                    <ul className="mobileSubHeader concertBg">
                        <li>
                            <HashLink to="/#about" scroll={(el) => scrollWithOffset(el)}>About</HashLink>
                        </li>
                        <li>
                            <HashLink to="/#contact" scroll={(el) => scrollWithOffset(el)}>Contact</HashLink>
                        </li>
                        <li>
                            <Link onClick={handleNavLinkClick} to="/member">
                                Member
                            </Link>
                        </li>
                    </ul>
                )}
            </div>
        </>
    );
}
