import React, { useCallback, useRef } from "react";
import { withRouter } from "react-router";
import app from "../base";

import { Card, Form, Button, Container } from 'react-bootstrap';
import Header from '../Components/Header';

const CreateNewAdmin = ({ history }) => {

  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();

  const handleSignUp = useCallback(async event => {
    event.preventDefault();
    if(passwordRef.current.value !== passwordConfirmRef.current.value){
      alert("passwords don't match");
      return;
    }

    try {
      await app
        .auth()
        .createUserWithEmailAndPassword(emailRef.current.value, passwordRef.current.value);
      history.push("/");
    } catch (error) {
      alert(error);
    }
  }, [history]);

  return (
    <>
      <Header />
      <div className="admin">
        <Container style={{ maxHeight: "100vh", maxWidth: "500px" }}>
          <div>
            <Card>
              <Card.Body>
                <h2 className="text-center mb-4">Skapa Ny Admin</h2>
                <Form onSubmit={handleSignUp}>
                  <Form.Group id="email">
                    <Form.Label>Användarnamn</Form.Label>
                    <Form.Control type="email" ref={emailRef} required />
                  </Form.Group>
                  <Form.Group id="password">
                    <Form.Label>Lösenord</Form.Label>
                    <Form.Control type="password" ref={passwordRef} required />
                  </Form.Group>
                  <Form.Group id="passwordConfirm">
                    <Form.Label>Upprepa lösenord</Form.Label>
                    <Form.Control type="password" ref={passwordConfirmRef} required />
                  </Form.Group>
                  <Button className="w-100 mt-6" type="submit">Skapa</Button>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </>
  );
};

export default withRouter(CreateNewAdmin);