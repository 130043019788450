import React, { useCallback, useContext, useRef } from "react";
import { withRouter, Redirect } from "react-router";

import { Card, Form, Button, Container } from 'react-bootstrap';

import app from "../base.js";
import { AuthContext } from "../Auth.js";
import Header from '../Components/Header';

const Login = ({ history }) => {
  const { currentUser } = useContext(AuthContext);
  const emailRef = useRef();
  const passwordRef = useRef();

  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      try {
        await app
          .auth()
          .signInWithEmailAndPassword(emailRef.current.value, passwordRef.current.value);
        history.push("/admin");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  if (currentUser) {
    return <Redirect to="/adminpage" />;
  }

  return (
    <>
      <Header />
      <div className="admin">
        <Container style={{ maxHeight: "100vh", maxWidth: "500px" }}>
          <div>
            <Card>
              <Card.Body>
                <h2 className="text-center mb-4">Log In</h2>
                <Form onSubmit={handleLogin}>
                  <Form.Group id="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control size="sm" type="email" ref={emailRef} required />
                  </Form.Group>
                  <Form.Group id="password" className="w-100 mb-5">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" ref={passwordRef} required />
                  </Form.Group>
                  <Button className="w-100 mt-6" type="submit">Login</Button>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </>
  );
};

export default withRouter(Login);