import React, { useContext, useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router";

import { Card, Form, Button, Container } from "react-bootstrap";
import uuid from "react-uuid";

import app from "../base.js";
import { AuthContext } from "../Auth.js";
import Header from "../Components/Header";
import styled from "styled-components";

const EditContentWrapper = styled.div`
    padding-top: 30px;
    @media (max-width: 900px) {
        padding-top: 180px;
    }
`;

const contactInitialState = {
    name: "",
    role: "",
    email: "",
};

const EditContent = ({ history }) => {
    const { currentUser } = useContext(AuthContext);
    const [jeppisJazzAbout, setJeppisJazzAbout] = useState();
    const [thursdayJazzAbout, setThursdayJazzAbout] = useState();
    const [jeppisJazzFestivalAbout, setJeppisJazzFestivalAbout] = useState();
    const [jakobBigBandAbout, setJakobBigBandAbout] = useState();
    const [backline, setBackline] = useState();
    const [thursdayJazzContact, setThursdayJazzContact] = useState(contactInitialState);
    const [jeppisJazzFestivalContact, setJeppisJazzFestivalContact] = useState(contactInitialState);
    const [jakobBigBandContact, setJakobBigBandContact] = useState(contactInitialState);
    const [concertsContact, setConcertsContact] = useState(contactInitialState);
    const [jeppisJazzFestivalDate, setJeppisJazzFestivalDate] = useState();
    const [memberInfoSe, setMemberInfoSe] = useState();
    const [memberInfoFi, setMemberInfoFi] = useState();
    const [memberInfoEn, setMemberInfoEn] = useState();

    const onChange = (e) => {
        if (e.target.id === "jeppisJazz") setJeppisJazzAbout(e.target.value);
        if (e.target.id === "thursdayJazz") setThursdayJazzAbout(e.target.value);
        if (e.target.id === "jeppisJazzFestival") setJeppisJazzFestivalAbout(e.target.value);
        if (e.target.id === "jakobBigBand") setJakobBigBandAbout(e.target.value);
        if (e.target.id === "backline") setBackline(e.target.value);
        if (e.target.id === "thursdayJazzContactName")
            setThursdayJazzContact({ ...thursdayJazzContact, name: e.target.value });
        if (e.target.id === "thursdayJazzContactRole")
            setThursdayJazzContact({ ...thursdayJazzContact, role: e.target.value });
        if (e.target.id === "thursdayJazzContactEmail")
            setThursdayJazzContact({ ...thursdayJazzContact, email: e.target.value });
        if (e.target.id === "jeppisJazzFestivalContactName")
            setJeppisJazzFestivalContact({ ...jeppisJazzFestivalContact, name: e.target.value });
        if (e.target.id === "jeppisJazzFestivalContactRole")
            setJeppisJazzFestivalContact({ ...jeppisJazzFestivalContact, role: e.target.value });
        if (e.target.id === "jeppisJazzFestivalContactEmail")
            setJeppisJazzFestivalContact({ ...jeppisJazzFestivalContact, email: e.target.value });
        if (e.target.id === "jakobBigBandContactName")
            setJakobBigBandContact({ ...jakobBigBandContact, name: e.target.value });
        if (e.target.id === "jakobBigBandContactRole")
            setJakobBigBandContact({ ...jakobBigBandContact, role: e.target.value });
        if (e.target.id === "jakobBigBandContactEmail")
            setJakobBigBandContact({ ...jakobBigBandContact, email: e.target.value });
        if (e.target.id === "concertsContactName")
            setConcertsContact({ ...concertsContact, name: e.target.value });
        if (e.target.id === "concertsContactRole")
            setConcertsContact({ ...concertsContact, role: e.target.value });
        if (e.target.id === "concertsContactEmail")
            setConcertsContact({ ...concertsContact, email: e.target.value });
        if (e.target.id === "jeppisJazzFestivalDate") setJeppisJazzFestivalDate(e.target.value);
        if (e.target.id === "memberInfoSe") setMemberInfoSe(e.target.value);
        if (e.target.id === "memberInfoFi") setMemberInfoFi(e.target.value);
        if (e.target.id === "memberInfoEn") setMemberInfoEn(e.target.value);
    };

    const handleJeppisJazzAbout = (e) => {
        if(jeppisJazzAbout.length === 0) return;
        e.preventDefault();
        let postRef = app.database().ref("content/jeppisJazzAbout");
        postRef.push({
            text: jeppisJazzAbout,
        });
        history.push("/");
    };

    const handleThursdayJazzAbout = (e) => {
        e.preventDefault();
        let postRef = app.database().ref("content/thursdayJazzAbout");
        postRef.push({
            text: thursdayJazzAbout,
        });
        history.push("/thursdayjazz");
    };

    const handleJeppisJazzFestivalAbout = (e) => {
        e.preventDefault();
        let postRef = app.database().ref("content/jeppisJazzFestivalAbout");
        postRef.push({
            text: jeppisJazzFestivalAbout,
        });
        history.push("/jeppisjazzfestival");
    };

    const handleJakobBigBandAbout = (e) => {
        e.preventDefault();
        let postRef = app.database().ref("content/jakobBigBandAbout");
        postRef.push({
            text: jakobBigBandAbout,
        });
        history.push("/jakobbigband");
    };

    const handleBackline = (e) => {
        e.preventDefault();
        let postRef = app.database().ref("content/backline");
        postRef.push({
            text: backline,
        });
        history.push("/backline");
    };

    const handleThursdayJazzContact = (e) => {
        e.preventDefault();
        let postRef = app.database().ref("content/thursdayJazzContact");
        postRef.push(thursdayJazzContact);
        history.push("/");
    };

    const handleJeppisJazzFestivalContact = (e) => {
        e.preventDefault();
        let postRef = app.database().ref("content/jeppisJazzFestivalContact");
        postRef.push(jeppisJazzFestivalContact);
        history.push("/");
    };

    const handleJakobBigBandContact = (e) => {
        e.preventDefault();
        let postRef = app.database().ref("content/jakobBigBandContact");
        postRef.push(jakobBigBandContact);
        history.push("/");
    };

    const handleConcertsContact = (e) => {
        e.preventDefault();
        let postRef = app.database().ref("content/concertsContact");
        postRef.push(concertsContact);
        history.push("/");
    };

    const handleJeppisJazzFestivalDate = (e) => {
        e.preventDefault();
        let postRef = app.database().ref("content/jeppisJazzFestivalDate");
        postRef.push({ text: jeppisJazzFestivalDate });
        history.push("/");
    };

    const handleMemberInfoSe = (e) => {
        e.preventDefault();
        let postRef = app.database().ref("content/memberInfoSe");
        postRef.push({ text: memberInfoSe });
        history.push("/");
    };

    const handleMemberInfoFi = (e) => {
        e.preventDefault();
        let postRef = app.database().ref("content/memberInfoFi");
        postRef.push({ text: memberInfoFi });
        history.push("/");
    };

    const handleMemberInfoEn = (e) => {
        e.preventDefault();
        let postRef = app.database().ref("content/memberInfoEn");
        postRef.push({ text: memberInfoEn });
        history.push("/");
    };

    return (
        <>
            <Header />
            <EditContentWrapper>
                <Container style={{ maxHeight: "100vh", maxWidth: "500px" }}>
                    <div>
                        <Card>
                            <Card.Body>
                                <h2 className="text-center mb-5">Redigera texter</h2>
                                <h3 className="my-3">About</h3>
                                <Form onSubmit={handleJeppisJazzAbout}>
                                    <Form.Group id="description">
                                        <Form.Label>Jeppis Jazz About</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="description"
                                            type="text"
                                            id="jeppisJazz"
                                            onChange={onChange}
                                            className="mb-2"
                                        />
                                    </Form.Group>

                                    <Button className="mb-3" type="submit">
                                        Spara
                                    </Button>
                                </Form>
                                <Form onSubmit={handleThursdayJazzAbout}>
                                    <Form.Group id="description">
                                        <Form.Label>Club About</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="description"
                                            type="text"
                                            id="thursdayJazz"
                                            onChange={onChange}
                                            className="mb-2"
                                        />
                                    </Form.Group>

                                    <Button className="mb-3" type="submit">
                                        Spara
                                    </Button>
                                </Form>
                                <Form onSubmit={handleJeppisJazzFestivalAbout}>
                                    <Form.Group id="description">
                                        <Form.Label>Jeppis Jazz Festival About</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="description"
                                            type="text"
                                            id="jeppisJazzFestival"
                                            onChange={onChange}
                                            className="mb-2"
                                        />
                                    </Form.Group>

                                    <Button className="mb-3" type="submit">
                                        Spara
                                    </Button>
                                </Form>
                                <Form onSubmit={handleJakobBigBandAbout}>
                                    <Form.Group id="description">
                                        <Form.Label>Jakob Big Band About</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="description"
                                            type="text"
                                            id="jakobBigBand"
                                            onChange={onChange}
                                            className="mb-2"
                                        />
                                    </Form.Group>

                                    <Button className="mb-3" type="submit">
                                        Spara
                                    </Button>
                                </Form>
                                <h3 className="my-3">Contact</h3>
                                <Form onSubmit={handleThursdayJazzContact}>
                                    <h5>Thursday jazz</h5>
                                    <Form.Group id="description">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            name="description"
                                            type="text"
                                            id="thursdayJazzContactName"
                                            onChange={onChange}
                                            className="mb-2"
                                            required
                                        />
                                        <Form.Label>Role</Form.Label>
                                        <Form.Control
                                            name="description"
                                            type="text"
                                            id="thursdayJazzContactRole"
                                            onChange={onChange}
                                            className="mb-2"
                                            required
                                        />
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            name="description"
                                            type="text"
                                            id="thursdayJazzContactEmail"
                                            onChange={onChange}
                                            className="mb-2"
                                            required
                                        />
                                    </Form.Group>

                                    <Button className="mb-3" type="submit">
                                        Spara
                                    </Button>
                                </Form>
                                <Form onSubmit={handleJeppisJazzFestivalContact}>
                                    <h5>Jeppis Jazz Festival</h5>
                                    <Form.Group id="description">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            name="description"
                                            type="text"
                                            id="jeppisJazzFestivalContactName"
                                            onChange={onChange}
                                            className="mb-2"
                                            required
                                        />
                                        <Form.Label>Role</Form.Label>
                                        <Form.Control
                                            name="description"
                                            type="text"
                                            id="jeppisJazzFestivalContactRole"
                                            onChange={onChange}
                                            className="mb-2"
                                            required
                                        />
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            name="description"
                                            type="text"
                                            id="jeppisJazzFestivalContactEmail"
                                            onChange={onChange}
                                            className="mb-2"
                                            required
                                        />
                                    </Form.Group>

                                    <Button className="mb-3" type="submit">
                                        Spara
                                    </Button>
                                </Form>
                                <Form onSubmit={handleJakobBigBandContact}>
                                    <h5>Jakob Big band</h5>
                                    <Form.Group id="description">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            name="description"
                                            type="text"
                                            id="jakobBigBandContactName"
                                            onChange={onChange}
                                            className="mb-2"
                                            required
                                        />
                                        <Form.Label>Role</Form.Label>
                                        <Form.Control
                                            name="description"
                                            type="text"
                                            id="jakobBigBandContactRole"
                                            onChange={onChange}
                                            className="mb-2"
                                            required
                                        />
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            name="description"
                                            type="text"
                                            id="jakobBigBandContactEmail"
                                            onChange={onChange}
                                            className="mb-2"
                                            required
                                        />
                                    </Form.Group>

                                    <Button className="mb-3" type="submit">
                                        Spara
                                    </Button>
                                </Form>
                                <Form onSubmit={handleConcertsContact}>
                                    <h5>Concerts</h5>
                                    <Form.Group id="description">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            name="description"
                                            type="text"
                                            id="concertsContactName"
                                            onChange={onChange}
                                            className="mb-2"
                                            required
                                        />
                                        <Form.Label>Role</Form.Label>
                                        <Form.Control
                                            name="description"
                                            type="text"
                                            id="concertsContactRole"
                                            onChange={onChange}
                                            className="mb-2"
                                            required
                                        />
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            name="description"
                                            type="text"
                                            id="concertsContactEmail"
                                            onChange={onChange}
                                            className="mb-2"
                                            required
                                        />
                                    </Form.Group>

                                    <Button className="mb-3" type="submit">
                                        Spara
                                    </Button>
                                </Form>
                                <h3>Member Info</h3>
                                <Form onSubmit={handleMemberInfoSe}>
                                    <Form.Label>Member Info Se</Form.Label>
                                    <Form.Group id="description">
                                        <Form.Control
                                            as="textarea"
                                            name="description"
                                            type="text"
                                            id="memberInfoSe"
                                            onChange={onChange}
                                            className="mb-2"
                                        />
                                    </Form.Group>

                                    <Button className="mb-3" type="submit">
                                        Spara
                                    </Button>
                                </Form>
                                <Form onSubmit={handleMemberInfoFi}>
                                    <Form.Label>Member Info Fi</Form.Label>
                                    <Form.Group id="description">
                                        <Form.Control
                                            as="textarea"
                                            name="description"
                                            type="text"
                                            id="memberInfoFi"
                                            onChange={onChange}
                                            className="mb-2"
                                        />
                                    </Form.Group>

                                    <Button className="mb-3" type="submit">
                                        Spara
                                    </Button>
                                </Form>
                                <Form onSubmit={handleMemberInfoEn}>
                                    <Form.Label>Member Info En</Form.Label>
                                    <Form.Group id="description">
                                        <Form.Control
                                            as="textarea"
                                            name="description"
                                            type="text"
                                            id="memberInfoEn"
                                            onChange={onChange}
                                            className="mb-2"
                                        />
                                    </Form.Group>

                                    <Button className="mb-3" type="submit">
                                        Spara
                                    </Button>
                                </Form>
                                <h3>Misc</h3>
                                <Form onSubmit={handleBackline}>
                                    <Form.Group id="description">
                                        <Form.Label>Backline</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="description"
                                            type="text"
                                            id="backline"
                                            onChange={onChange}
                                            className="mb-2"
                                        />
                                    </Form.Group>

                                    <Button className="mb-3" type="submit">
                                        Spara
                                    </Button>
                                </Form>
                                <Form onSubmit={handleJeppisJazzFestivalDate}>
                                    <Form.Label>Jeppis Jazz Festival Date</Form.Label>
                                    <Form.Group id="description">
                                        <Form.Control
                                            name="description"
                                            type="text"
                                            id="jeppisJazzFestivalDate"
                                            onChange={onChange}
                                            className="mb-2"
                                        />
                                    </Form.Group>

                                    <Button className="mb-3" type="submit">
                                        Spara
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </Container>
            </EditContentWrapper>
        </>
    );
};

export default withRouter(EditContent);
