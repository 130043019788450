import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../Components/Header";
import { AuthContext } from "../Auth";
import app from "../base";

const db = app.database();

const BacklineWrapper = styled.div`
    padding: 20px 0;
    @media (max-width: 900px) {
        padding-top: 180px;
    }
`;

const BacklineContent = styled.div`
    padding: 40px 0;
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    background-color: red;
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    white-space: pre-wrap;
`;

const BacklineTitle = styled.p`
    font-size: 30px;
    margin-bottom: 30px;
`;

export default function Backline() {
    const { setCurrentPageHandler, currentPage } = useContext(AuthContext);
    const [backline, setBackline] = useState();

    const onMouseOverHandler = () => {
        setCurrentPageHandler(currentPage + 1)
    }

    useEffect(() => {
        var backlineArray;
        const backlineRef = db.ref("content/backline");

        backlineRef.on("value", (snapshot) => {
            const backlines = snapshot.val();
            const backlineList = [];

            for (let id in backlines) {
                backlineList.push({ id, ...backlines[id] });
            }

            backlineArray = backlineList;

            setBackline(backlineArray[backlineArray.length - 1]);
        });
    }, [])

    return (
        <div>
            <Header />
            <BacklineWrapper onMouseEnter={onMouseOverHandler} className="backlineBg">
                <BacklineContent>
                    <BacklineTitle>Backline</BacklineTitle>
                    {backline && backline.text}
                </BacklineContent>
            </BacklineWrapper>
        </div>
    );
}
