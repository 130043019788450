import React from 'react'
import { useHistory } from 'react-router-dom';
import { chooseClassName, formatDate } from '../utils';

const EventSmallCard = (props) => {
    const history = useHistory();

    const handleClick = () => {
        let mappedCategory;
        if(props.category === "Jakob Big Band") { mappedCategory = "jakobbigband" }
        if(props.category === "Jeppis Jazz Festival") { mappedCategory = "jeppisjazzfestival" }
        if(props.category === "Thursday Jazz") { mappedCategory = "thursdayjazz" }
        if(props.category === "Concert") { mappedCategory = "concert"}
        history.push(`/${mappedCategory}/${props.id}`);
    }

    return (
        <div className="eventSmallCard">
            <div className="eventSmallCardImage">
                <img src={props.image} alt={props.title} />
                <p className={`imgCategory ${chooseClassName(props.category)}`}>{props.category}</p>
            </div>
            <div className="eventSmallCardText">
                <p className="eventSmallCardDate">{formatDate(props.date)}</p>
                <p className="eventSmallCardTime">kl. {props.time}</p>
                <p className="eventSmallCardPlace">{props.place.substring(0, 30)}{props.place.length > 30 && "..."}</p>
                <h3 className="eventSmallCardTitle">{props.title.substring(0, 35)}{props.title.length > 35 && "..."}</h3>
                <button onClick={handleClick} className="infoButton">Info</button>
            </div>
        </div>
    )
}

export default EventSmallCard;