import React, { useEffect, useState, useReducer } from "react";
import { filterPastPosts } from "../utils";

import app from "../base";
import EventSmallCard from "../Components/EventSmallCard";
import NextEventCard from "../Components/NextEventCard";
import Header from "../Components/Header";
import Contact from "../Components/Contact";
import thursdayJazzLogo from "../Components/thursdayJazzLogo.png";
import jeppisJazzFestivalLogoBlue from "../Components/jeppisJazzFestivalLogoBlue.png";
import jakobBigBandLogo from "../Components/JBBLogo.jpg";
import InstagramEmbed from "react-instagram-embed";

const db = app.database();

export default function CategoryPage({ category }) {
    const [posts, setPosts] = useState();
    const [postList, setPostList] = useState();
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    const chooseBannerBg = (category) => {
        if (category === "Jeppis Jazz Festival") return "jeppisJazzFestivalBannerBg";
        if (category === "Jakob Big Band") return "jakobBigBandBannerBg";
        if (category === "Thursday Jazz") return "thursdayJazzBannerBg";
    };

    const chooseLogo = (category) => {
        if (category === "Jeppis Jazz Festival") return jeppisJazzFestivalLogoBlue;
        if (category === "Jakob Big Band") return jakobBigBandLogo;
        if (category === "Thursday Jazz") return thursdayJazzLogo;
    };

    useEffect(() => {
        var postsArray;
        const ref = db.ref("posts");

        ref.on("value", (snapshot) => {
            const posts = snapshot.val();
            const postList = [];

            for (let id in posts) {
                postList.push({ id, ...posts[id] });
            }

            postsArray = postList;

            const filteredPosts = filterPastPosts(category, postsArray);

            setPostList(filteredPosts);
        });
        return postsArray;
    }, [window.location.href]);

    if (!postList || postList.length === 0)
        return (
            <>
                <Header forceUpdate={forceUpdate} />
                <div className={`categoryBanner noEventsContainer ${chooseBannerBg(category)}`}>
                    <p className="noEvents">No upcoming events, please check back soon.</p>
                </div>
            </>
        );

    return (
        <>
            <Header forceUpdate={forceUpdate} />
            <div key={Math.random()} className="events">
                <div className="upComingEvents">
                    <h3 className="upComingEventsTitle">Past Events</h3>
                    <div className="eventSmallCardContainer">
                        {postList
                            ? postList.map((post, index) => (
                                  <EventSmallCard
                                      category={post.category}
                                      image={post.fileUrl}
                                      date={post.date}
                                      time={post.time}
                                      place={post.place}
                                      title={post.title}
                                      description={post.description}
                                      address={post.address}
                                      price={post.price}
                                      mediaLink={post.mediaLink}
                                      bandMembers={post.bandMembers}
                                      createdBy={post.createdBy}
                                      id={post.id}
                                  />
                              ))
                            : ""}
                    </div>
                </div>
            </div>
            <InstagramEmbed
                url="https://www.instagram.com/p/CUmRC-WgrMY/?utm_source=ig_web_copy_link"
                maxWidth={320}
                hideCaption={false}
                containerTagName="div"
                protocol=""
                injectScript
                onLoading={() => {}}
                onSuccess={() => {}}
                onAfterRender={() => {}}
                onFailure={() => {}}
            />
            <Contact category={category} />
        </>
    );
}
