import React, { useContext, useRef, useEffect, useState } from "react";
import { filterPosts, filterPastPosts, chooseColor } from "../utils";

import app from "../base";
import EventSmallCard from "../Components/EventSmallCard";
import NextEventCard from "../Components/NextEventCard";
import Header from "../Components/Header";
import Contact from "../Components/Contact";
import logo from "../Components/jeppisJazzLogo.png";
import Gallery from "./Gallery";
import About from "../Components/About";
import { AuthContext } from "../Auth";
import styled from "styled-components";

const db = app.database();

const MoreEventsBtn = styled.button.attrs(props =>({
    className: "thursdayJazzColor"
}))`
    font-size: 3rem;
    width: 100%;
    background-color: white;
    border: none;
    cursor: pointer;
`

export default function CategoryPage({ category, handleOnMouseOverBanner, activeMenuItem }) {
    const [postList, setPostList] = useState();
    const [pastPostList, setPastPostList] = useState();
    const galleryRef = useRef();
    const [pastEventsVisible, setPastEventsVisible] = useState(3);

    const { setCurrentPageHandler, currentPage } = useContext(AuthContext);


    const chooseBannerBg = (category) => {
        if (category === "Jeppis Jazz Festival") return "jeppisJazzFestivalBannerBg";
        if (category === "Jakob Big Band") return "jakobBigBandBannerBg";
        if (category === "Thursday Jazz") return "thursdayJazzBannerBg";
    };

    const chooseLogo = (category) => {
        // if (category === "Jeppis Jazz Festival") return jeppisJazzFestivalLogoBlue;
        // if (category === "Jakob Big Band") return jakobBigBandLogo;
        // if (category === "Thursday Jazz") return thursdayJazzLogo;
        return logo;
    };

    const onMouseOverHandler = () => {
        setCurrentPageHandler(currentPage + 1)
    }

    const moreEventsHandler = () => {
        setPastEventsVisible(pastEventsVisible + 3);
    }

    useEffect(() => {
        var postsArray;
        const ref = db.ref("posts");

        ref.on("value", (snapshot) => {
            const posts = snapshot.val();
            const postList = [];

            for (let id in posts) {
                postList.push({ id, ...posts[id] });
            }

            postsArray = postList;

            const filteredPosts = filterPosts(category, postsArray);
            const filteredPastPosts = filterPastPosts(category, postsArray);

            setPostList(filteredPosts);
            setPastPostList(filteredPastPosts);
        });
    }, [window.location.href]);

    return (
        <>
            <Header/>
            <div onMouseEnter={onMouseOverHandler} key={Math.random()} className="events">
                <div className={`categoryBanner ${chooseBannerBg(category)}`}>
                    <img src={chooseLogo(category)}></img>
                </div>
                {console.log(postList)}
            {postList === undefined || postList.length === 0 ? <></> : <>
                <div id="events" className="nextEvent">
                    <h3 className="nextEventTitle">Next Event</h3>
                    
                        <NextEventCard
                            category={postList[0].category}
                            image={postList[0].fileUrl}
                            date={postList[0].date}
                            time={postList[0].time}
                            place={postList[0].place}
                            title={postList[0].title}
                            description={postList[0].description}
                            price={postList[0].price}
                            place={postList[0].place}
                            address={postList[0].address}
                            bandMembers={postList[0].bandMembers}
                            mediaLink={postList[0].mediaLink}
                            createdBy={postList[0].createdBy}
                            id={postList[0].id}
                        />
                    
                </div>
                {postList[1] && <div className="upComingEvents">
                    <h3 className="upComingEventsTitle">Upcoming Events</h3>
                    <div className="eventSmallCardContainer">
                        {postList
                                  .slice(1)
                                  .map((post, index) => (
                                      <EventSmallCard
                                          category={post.category}
                                          image={post.fileUrl}
                                          date={post.date}
                                          time={post.time}
                                          place={post.place}
                                          title={post.title}
                                          description={post.description}
                                          address={post.address}
                                          price={post.price}
                                          mediaLink={post.mediaLink}
                                          bandMembers={post.bandMembers}
                                          createdBy={post.createdBy}
                                          id={post.id}
                                      />
                                  ))
                            }
                    </div>
                </div>}
                </>}

                <div className="upComingEvents">
                    <h3 className="upComingEventsTitle">Past Events</h3>
                    <div className="eventSmallCardContainer">
                        {pastPostList
                            ? pastPostList.slice(0, pastEventsVisible)
                                  .map((post, index) => (
                                      <EventSmallCard
                                          category={post.category}
                                          image={post.fileUrl}
                                          date={post.date}
                                          time={post.time}
                                          place={post.place}
                                          title={post.title}
                                          description={post.description}
                                          address={post.address}
                                          price={post.price}
                                          mediaLink={post.mediaLink}
                                          bandMembers={post.bandMembers}
                                          createdBy={post.createdBy}
                                          id={post.id}
                                      />
                                  ))
                            : ""}
                    </div>
                </div>
                {pastPostList && (pastEventsVisible <= pastPostList.length)  && <MoreEventsBtn className={`${chooseColor(category)}`} onClick={moreEventsHandler}><i class="fas fa-plus"></i></MoreEventsBtn>}
            </div>
            {category === "Jeppis Jazz Festival" && <Gallery ref={galleryRef} />}
            <About category={pastPostList && pastPostList[0].category}/>
            <Contact category={category} />
        </>
    );
}
