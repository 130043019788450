import React from 'react'
import { Link } from 'react-router-dom';
import { Button, Card, Container } from 'react-bootstrap';

import app from "../base.js";
import Header from '../Components/Header';

export default function AdminPage() {
    return (
        <>
        <Header />
        <div className="admin">
        <Container style={{maxWidth: "500px"}} >
            <Card style={{border: "none"}}>
                <Card.Body style={{ margin: "auto 0", border: "none" }} className="d-flex flex-column align-items-center justify-content-center">
                    <Link className="mb-3" to="/addpost"><Button style={{width: "320px", height: "100px", fontSize:"1.5rem", backgroundColor:"black", border: "none"}}><i className="fas fa-calendar-alt"></i> Skapa nytt evenemang</Button></Link>
                    <Link className="mb-3" to="/createnewadmin"><Button style={{width: "320px", height: "100px", fontSize:"1.5rem", backgroundColor:"black", border: "none"}}><i className="fas fa-user-plus"></i> Skapa ny Admin</Button></Link>
                    <Link className="mb-3" to="/addphoto"><Button style={{width: "320px", height: "100px", fontSize:"1.5rem", backgroundColor:"black", border: "none"}}><i className="far fa-file-image"></i> Ladda upp gallerifoto</Button></Link>
                    <Link to="/editcontent"><Button style={{width: "320px", height: "100px", fontSize:"1.5rem", backgroundColor:"black", border: "none"}}><i className="fa-solid fa-paragraph"></i> Redigera texter</Button></Link>
                    <Button style={{width: "320px", height: "100px", fontSize:"1.5rem"}} variant="warning" className="mt-3" onClick={() => app.auth().signOut()}><i class="fas fa-sign-out-alt"></i> Sign out</Button>
                </Card.Body>
            </Card>

        </Container>
        </div>
        </>
    )
}
