import React from "react";
import "./App.scss";
import "./Components/mobileHeader.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import AddPost from "./pages/AddPost";
import CreateNewAdmin from "./pages/CreateNewAdmin";
import AdminPage from "./pages/AdminPage";
import { AuthProvider } from "./Auth";
import PrivateRoute from "./pages/PrivateRoute";
import CategoryPage from "./pages/CategoryPage";
import ShowPost from "./pages/ShowPost";
import EditPost from "./pages/EditPost";
import PastEvents from "./pages/PastEvents";
import Member from "./pages/Member";
import AddPhoto from "./pages/AddPhoto";
import Gallery from './pages/Gallery';
import Backline from "./pages/Backline";
import EditContent from './pages/EditContent';

const App = () => {

    return (
        <AuthProvider>
            <Router>
                <Switch>
                    <PrivateRoute exact path="/admin" component={AdminPage} />
                    <PrivateRoute exact path="/createnewadmin" component={CreateNewAdmin} />
                    <PrivateRoute exact path="/addpost" component={AddPost} />
                    <PrivateRoute exact path="/editpost/:id" component={EditPost} />
                    <PrivateRoute exact path="/addphoto" component={AddPhoto} />
                    <PrivateRoute exact path="/editcontent" component={EditContent} />
                    <Route exact path="/" component={Home} />
                    <Route exact path="/pastevents" component={PastEvents} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/jeppisjazzfestival">
                        <CategoryPage category={"Jeppis Jazz Festival"} />
                    </Route>
                    <Route exact path="/thursdayjazz">
                        <CategoryPage category={"Thursday Jazz"} />
                    </Route>
                    <Route exact path="/jakobbigband">
                        <CategoryPage category={"Jakob Big Band"} />
                    </Route>
                    <Route exact path="/:category/:id">
                        <ShowPost />
                    </Route>
                    <Route exact path="/member">
                        <Member />
                    </Route>
                    <Route exact path="/gallery">
                        <Gallery />
                    </Route>
                    <Route exact path="/backline">
                        <Backline />
                    </Route>
                </Switch>
            </Router>
        </AuthProvider>
    );
};

export default App;
