import React, { useContext, useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router";
import { useParams } from "react-router-dom";

import { Card, Form, Button, Container } from "react-bootstrap";

import app from "../base.js";
import { AuthContext } from "../Auth.js";
import Header from "../Components/Header";

const db = app.database();

const EditPost = ({ history }) => {
    const { currentUser } = useContext(AuthContext);

    const [post, setPost] = useState({});
    const [fileUrl, setFileUrl] = useState(null);
    const bandMembers = [{ bandMemberName: "", bandMemberInstrument: "" }];
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState();

    const [formValues, setFormValues] = useState(bandMembers);

    const handleChange = (e) => {
        setPost({ ...post, [e.target.name]: e.target.value });
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        const storageRef = app.storage().ref();
        const fileRef = storageRef.child(new Date().toISOString() + file.name);
        await fileRef.put(file);
        setFileUrl(await fileRef.getDownloadURL());
    };

    let handleBandMembersChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    };

    let addFormFields = () => {
        setFormValues([...formValues, { bandMemberName: "", bandMemberInstrument: "" }]);
    };

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);
    };

    const handleAddPost = (e) => {
        e.preventDefault();
        if (post.category === "0") {
            alert("Choose category");
            return;
        }
        let ref = app.database().ref("posts");

        if (fileUrl) {
            ref.child(id).update({ ...post, fileUrl, bandMembers: formValues });
        } else {
            ref.child(id).update({ ...post, bandMembers: formValues });
        }
        history.push(`/${post.category}/${id}`);
    };

    useEffect(() => {
        setIsLoading(true);
        var postsArray;
        const ref = db.ref("posts");

        ref.child(id).on("value", (snapshot) => {
            setPost(snapshot.val());
            setFormValues(snapshot.val().bandMembers);
            setIsLoading(false);
        });
    }, []);

    return (
        <>
            <Header />
            <div className="admin">
                <Container style={{ maxHeight: "100vh", maxWidth: "500px" }}>
                    <div>
                        <Card>
                            <Card.Body>
                                <h2 className="text-center mb-5">Redigera evenemang</h2>
                                <Form onSubmit={handleAddPost}>
                                    <Form.Group id="category">
                                        <div>
                                            <Form.Label htmlFor="inlineFormCustomSelect">
                                                Kategori *
                                            </Form.Label>
                                        </div>
                                        <Form.Control
                                            name="category"
                                            onChange={handleChange}
                                            as="select"
                                            className="mb-3"
                                            id="inlineFormCustomSelect"
                                            custom
                                            value={post.category}
                                            style={{
                                                width: "100%",
                                                height: "40px",
                                                border: "1px solid lightgray",
                                                borderRadius: "5px",
                                                padding: "6px 10px",
                                            }}>
                                            <option value="0">Välj...</option>
                                            <option value="Thursday Jazz">Thursday Jazz</option>
                                            <option value="Jeppis Jazz Festival">
                                                Jeppis Jazz Festival
                                            </option>
                                            <option value="Jakob Big Band">Jakob Big Band</option>
                                            <option value="Konsert">Konsert</option>
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group id="image" controlId="formFile">
                                        <div>
                                            <Form.Label>Foto *</Form.Label>
                                        </div>
                                        <Form.Control
                                            className="mb-4"
                                            name="image"
                                            onChange={handleFileChange}
                                            type="file"
                                            required
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Group>

                                    <Form.Group id="date">
                                        <Form.Label>Datum *</Form.Label>
                                        <Form.Control
                                            className="mb-3"
                                            name="date"
                                            onChange={handleChange}
                                            type="date"
                                            required
                                            value={post.date}
                                        />
                                    </Form.Group>
                                    <Form.Group id="time">
                                        <Form.Label>Klockslag *</Form.Label>
                                        <Form.Control
                                            className="mb-3"
                                            name="time"
                                            onChange={handleChange}
                                            type="time"
                                            required
                                            value={post.time}
                                        />
                                    </Form.Group>
                                    <Form.Group id="title">
                                        <Form.Label>Titel *</Form.Label>
                                        <Form.Control
                                            className="mb-3"
                                            name="title"
                                            onChange={handleChange}
                                            type="text"
                                            required
                                            value={post.title}
                                        />
                                    </Form.Group>
                                    <Form.Group id="place">
                                        <Form.Label>Plats *</Form.Label>
                                        <Form.Control
                                            className="mb-3"
                                            name="place"
                                            onChange={handleChange}
                                            type="text"
                                            required
                                            value={post.place}
                                        />
                                    </Form.Group>
                                    <Form.Group id="address">
                                        <Form.Label>Adress *</Form.Label>
                                        <Form.Control
                                            className="mb-3"
                                            name="address"
                                            onChange={handleChange}
                                            type="text"
                                            required
                                            value={post.address}
                                        />
                                    </Form.Group>
                                    <Form.Group id="price">
                                        <Form.Label>Inträde *</Form.Label>
                                        <Form.Control
                                            className="mb-3"
                                            name="price"
                                            onChange={handleChange}
                                            type="text"
                                            required
                                            value={post.price}
                                        />
                                    </Form.Group>
                                    <Form.Group id="mediaLink">
                                        <Form.Label>Media Link</Form.Label>
                                        <Form.Control
                                            className="mb-3"
                                            name="mediaLink"
                                            onChange={handleChange}
                                            type="text"
                                            value={post.mediaLink ? post.mediaLink : ""}
                                        />
                                    </Form.Group>
                                    <Form.Group id="description">
                                        <Form.Label>Beskrivning *</Form.Label>
                                        <Form.Control
                                            className="mb-3"
                                            as="textarea"
                                            name="description"
                                            type="text"
                                            onChange={handleChange}
                                            required
                                            value={post.description}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className="mt-5" style={{ fontSize: "1.2rem" }}>
                                            Bandmedlemmar
                                        </Form.Label>
                                        {formValues.map((element, index) => (
                                            <div key={index} className="mb-3">
                                                <Form.Group>
                                                    <Form.Label>Namn</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="bandMemberName"
                                                        value={element.bandMemberName || ""}
                                                        onChange={(e) =>
                                                            handleBandMembersChange(index, e)
                                                        }
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>Instrument</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="bandMemberInstrument"
                                                        value={element.bandMemberInstrument || ""}
                                                        onChange={(e) =>
                                                            handleBandMembersChange(index, e)
                                                        }
                                                    />
                                                    {index ? (
                                                        <Button
                                                            variant="danger"
                                                            type="button"
                                                            className=""
                                                            onClick={() => removeFormFields(index)}>
                                                            Ta bort
                                                        </Button>
                                                    ) : null}
                                                </Form.Group>
                                            </div>
                                        ))}
                                        <div className="button-section">
                                            <Button
                                                className="mt-3"
                                                variant="success"
                                                type="button"
                                                onClick={() => addFormFields()}>
                                                +1 bandmedlem
                                            </Button>
                                        </div>
                                    </Form.Group>

                                    <Button
                                        onClick={handleAddPost}
                                        className="mt-5 w-100"
                                        // type="submit"
                                    >
                                        Spara evenemang
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                        {/* <Button variant="warning" className="mt-3 mb-5" onClick={() => app.auth().signOut()}>Sign out</Button> */}
                    </div>
                </Container>
            </div>
        </>
    );
};

export default withRouter(EditPost);
// export default EditPost;
