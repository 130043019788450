import React, { useRef, useContext, useState, useEffect } from "react";
import { Link, useLocation, useHistory, useRouteMatch } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import jeppisJazzlogo from "../Components/jeppisJazzLogo.png";
import MobileHeader from "./MobileHeader";
import { AuthContext } from "../Auth";
import { HashLink } from "react-router-hash-link";
import styled from "styled-components";
import app from "../base";

const db = app.database();

const HeaderWrapper = styled.div`
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2;
`;

const FestivalDateWrapper = styled.span`
position: absolute;
bottom: 25px;
// transform: rotate(-20deg);
// margin-left: 80px;
font-size: .7rem;
// color: black;
text-transform: capitalize;
`;

export default function Header() {
    const navLinkRef = useRef();
    const [isJeppisJazz, setIsJeppisJazz] = useState();
    const [isThursdayJazz, setIsThursdayJazz] = useState(false);
    const [isJeppisJazzFestival, setIsJeppisJazzFestival] = useState(false);
    const [isJakobBigBand, setIsJakobBigBand] = useState(false);
    const [isJeppisJazzClicked, setisJeppisJazzClicked] = useState();
    const [isThursdayJazzClicked, setIsThursdayJazzClicked] = useState();
    const [isJakobBigBandClicked, setIsJakobBigBandClicked] = useState();
    const [currentUrl, setCurrentUrl] = useState();
    const [jeppisJazzFestivalDate, setJeppisJazzFestivalDate] = useState();
    const [isShowPostPage, setIsShowPost] = useState();
    const location = useLocation();

    const { currentPage } = useContext(AuthContext);

    const history = useHistory();

    const onDateHover  = () => {
            setIsJakobBigBand(false);
            setIsJeppisJazzFestival(true);
            setIsThursdayJazz(false);
            setIsJeppisJazz(false);
            setisJeppisJazzClicked(true);
            setIsThursdayJazzClicked(false);
            setIsJakobBigBandClicked(false);
    }

    const onDateClick = () => {
        history.push("/jeppisjazzfestival");
    }

    const handleOnMouseOver = (e) => {
        
        if (e.taget === "a.jeppisJazzFestivalHeader-active") {
            setIsJakobBigBand(false);
            setIsJeppisJazzFestival(false);
            setIsThursdayJazz(false);
            setIsJeppisJazz(true);
            setisJeppisJazzClicked(false);
            setIsThursdayJazzClicked(false);
            setIsJakobBigBandClicked(false);
        }
        if (e.target.href && e.target.href.includes("thursdayjazz")) {
            setIsJakobBigBand(false);
            setIsJeppisJazzFestival(false);
            setIsThursdayJazz(true);
            setIsJeppisJazz(false);
            setisJeppisJazzClicked(false);
            setIsThursdayJazzClicked(true);
            setIsJakobBigBandClicked(false);
        }
        if (e.target.href && e.target.href.includes("jakobbigband")) {
            setIsJakobBigBand(true);
            setIsJeppisJazzFestival(false);
            setIsThursdayJazz(false);
            setIsJeppisJazz(false);
            setisJeppisJazzClicked(false);
            setIsThursdayJazzClicked(false);
            setIsJakobBigBandClicked(true);
        }
        if (e.target.href && e.target.href.includes("jeppisjazzfestival")) {
            setIsJakobBigBand(false);
            setIsJeppisJazzFestival(true);
            setIsThursdayJazz(false);
            setIsJeppisJazz(false);
            setisJeppisJazzClicked(true);
            setIsThursdayJazzClicked(false);
            setIsJakobBigBandClicked(false);
        }
    };

    const handleNavLinkClick = (e) => {
        scroll.scrollToTop({ duration: 0 });
        document.body.classList.remove("nav-open");
        if (e.target.href && e.target.href.includes("jakobbigband")) {
            setIsJakobBigBand(true);
            setIsJeppisJazzFestival(false);
            setIsThursdayJazz(false);
            setIsJeppisJazz(false);
            setisJeppisJazzClicked(false);
            setIsThursdayJazzClicked(false);
            setIsJakobBigBandClicked(true);
        } else if (e.target.href && e.target.href.includes("jeppisjazzfestival")) {
            setIsJakobBigBand(false);
            setIsJeppisJazzFestival(true);
            setIsThursdayJazz(false);
            setIsJeppisJazz(false);
            setisJeppisJazzClicked(true);
            setIsThursdayJazzClicked(false);
            setIsJakobBigBandClicked(false);
        } else if (e.target.href && e.target.href.includes("thursdayjazz")) {
            setIsJakobBigBand(false);
            setIsJeppisJazzFestival(false);
            setIsThursdayJazz(true);
            setIsJeppisJazz(false);
            setisJeppisJazzClicked(false);
            setIsThursdayJazzClicked(true);
            setIsJakobBigBandClicked(false);
        } else {
            setIsJakobBigBand(false);
            setIsJeppisJazzFestival(true);
            setIsThursdayJazz(false);
            setIsJeppisJazz(false);
        }
    };

    const navigateToHome = () => {
        scroll.scrollToTop({ duration: 0 });
        history.push("/");
    };

    useEffect(() => {
        var jeppisJazzFestivalDateArray;
        const jeppisJazzFestivalDateRef = db.ref("content/jeppisJazzFestivalDate");

        jeppisJazzFestivalDateRef.on("value", (snapshot) => {
            const jeppisJazzFestivalDates = snapshot.val();
            const jeppisJazzFestivalDateList = [];

            for (let id in jeppisJazzFestivalDates) {
                jeppisJazzFestivalDateList.push({ id, ...jeppisJazzFestivalDates[id] });
            }

            jeppisJazzFestivalDateArray = jeppisJazzFestivalDateList;

            setJeppisJazzFestivalDate(
                jeppisJazzFestivalDateArray[jeppisJazzFestivalDateArray.length - 1]
            );
        });
    }, []);

    useEffect(() => {
        setCurrentUrl(window.location.href);
        if (location.pathname.split("/").length === 3) {
            setIsShowPost(true);
        }
    }, []);

    useEffect(() => {
        if (window.location.href.includes("jakobbigband")) {
            setIsJakobBigBand(true);
            setIsJeppisJazzFestival(false);
            setIsThursdayJazz(false);
            setIsJeppisJazz(false);
            setisJeppisJazzClicked(false);
            setIsThursdayJazzClicked(false);
            setIsJakobBigBandClicked(true);
        } else if (window.location.href.includes("jeppisjazzfestival")) {
            setIsJakobBigBand(false);
            setIsJeppisJazzFestival(true);
            setIsThursdayJazz(false);
            setIsJeppisJazz(false);
            setisJeppisJazzClicked(true);
            setIsThursdayJazzClicked(false);
            setIsJakobBigBandClicked(false);
        } else if (
            window.location.href.includes("thursdayjazz") ||
            window.location.href.includes("backline")
        ) {
            setIsJakobBigBand(false);
            setIsJeppisJazzFestival(false);
            setIsThursdayJazz(true);
            setIsJeppisJazz(false);
            setisJeppisJazzClicked(false);
            setIsThursdayJazzClicked(true);
            setIsJakobBigBandClicked(false);
        } else if (
            window.location.href.includes("admin") ||
            window.location.href.includes("login") ||
            window.location.href.includes("addphoto")
        ) {
            setIsJakobBigBand(false);
            setIsJeppisJazzFestival(false);
            setIsThursdayJazz(false);
            setIsJeppisJazz(false);
        } else {
            setIsJakobBigBand(false);
            setIsJeppisJazzFestival(false);
            setIsThursdayJazz(false);
            setIsJeppisJazz(true);
        }

        return function cleanUp() {
            setIsJakobBigBand(false);
            setIsJeppisJazzFestival(false);
            setIsThursdayJazz(false);
            setIsJeppisJazz(false);
            setisJeppisJazzClicked(false);
            setIsThursdayJazzClicked(false);
            setIsJakobBigBandClicked(false);
        };
    }, [currentUrl, currentPage]);

    return (
        <>
            <HeaderWrapper>
                <div className="header">
                    <ul className="headerMenu">
                        <li onMouseOver={handleOnMouseOver} className="logo">
                            <img onClick={navigateToHome} src={jeppisJazzlogo}></img>
                        </li>
                        <li ref={navLinkRef} onClick={handleNavLinkClick}>
                            <Link
                                onMouseOver={handleOnMouseOver}
                                className={`${
                                    isThursdayJazz
                                        ? "thursdayJazzHeader-active"
                                        : "thursdayJazzHeader"
                                }`}
                                to="/thursdayjazz">
                                Club
                            </Link>
                        </li>
                        <li ref={navLinkRef} onClick={handleNavLinkClick}>
                            <Link
                                onMouseOver={handleOnMouseOver}
                                className={`${
                                    isJeppisJazzFestival
                                        ? "jeppisJazzFestivalHeader-active"
                                        : "jeppisJazzFestivalHeader"
                                }`}
                                to="/jeppisjazzfestival">
                                Festival
                                <FestivalDateWrapper onClick={onDateClick} onMouseOver={onDateHover}>
                                    {jeppisJazzFestivalDate && jeppisJazzFestivalDate.text}
                                </FestivalDateWrapper>
                            </Link>
                        </li>
                        <li ref={navLinkRef} onClick={handleNavLinkClick}>
                            <Link
                                onMouseOver={handleOnMouseOver}
                                className={`${
                                    isJakobBigBand
                                        ? "jakobBigBandHeader-active"
                                        : "jakobBigBandHeader"
                                }`}
                                to="/jakobbigband">
                                Jakob Big
                                <br />
                                Band
                            </Link>
                        </li>
                    </ul>
                </div>

                {isThursdayJazzClicked && (
                    <div className="subHeader thursdayJazzBg">
                        <ul className="subHeaderMenu">
                            <li>
                                <HashLink to="/thursdayjazz#about">About</HashLink>
                            </li>
                            <li>
                                <HashLink to="/thursdayjazz#contact">Contact</HashLink>
                            </li>
                            <li>
                                <Link to="/backline">Backline</Link>
                            </li>
                        </ul>
                    </div>
                )}
                {isJeppisJazzClicked && (
                    <div className="subHeader jeppisJazzFestivalBg">
                        <ul className="subHeaderMenu">
                            <li>
                                <HashLink to="/jeppisjazzfestival#about">About</HashLink>
                            </li>
                            <li>
                                <HashLink to="/jeppisjazzfestival#contact">Contact</HashLink>
                            </li>
                            <li>
                                <HashLink to="/jeppisjazzfestival#gallery">Gallery</HashLink>
                            </li>
                        </ul>
                    </div>
                )}
                {isJakobBigBandClicked && (
                    <div className="subHeader jakobBigBandBg">
                        <ul className="subHeaderMenu">
                            <li>
                                <HashLink to="/jakobbigband#about">About</HashLink>
                            </li>
                            <li>
                                <HashLink to="/jakobbigband#contact">Contact</HashLink>
                            </li>
                        </ul>
                    </div>
                )}
                {isJeppisJazz && (
                    <div className="subHeader concertBg">
                        <ul className="subHeaderMenu">
                            <li>
                                <HashLink to="/#about">About</HashLink>
                            </li>
                            <li>
                                <HashLink to="/#contact">Contact</HashLink>
                            </li>
                            <li>
                                <Link to="/member">Member</Link>
                            </li>
                        </ul>
                    </div>
                )}
            </HeaderWrapper>

            <MobileHeader jeppisJazzFestivalDate={jeppisJazzFestivalDate} />
        </>
    );
}
