import React, { useContext, useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router";

import { Card, Form, Button, Container } from "react-bootstrap";

import app from "../base.js";
import { AuthContext } from "../Auth.js";
import Header from "../Components/Header";

const AddPhoto = ({ history }) => {
    const { currentUser } = useContext(AuthContext);
    const [fileUrl, setFileUrl] = useState();

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        const storageRef = app.storage().ref();
        const fileRef = storageRef.child(new Date().toISOString() + file.name);
        await fileRef.put(file);
        const fileToSave = await fileRef.getDownloadURL()
        setFileUrl(fileToSave);
    };

    const handleAddPhoto = (e) => {
        e.preventDefault();
        let postRef = app.database().ref("galleryPhotos");
        postRef.push({
            fileUrl: JSON.parse(JSON.stringify(fileUrl)),
            createdBy: JSON.parse(JSON.stringify(currentUser.email)),
        });
        history.push("/jeppisjazzfestival", "gallery");
    };

    return (
        <>
            <Header />
            <div className="admin">
                <Container style={{ maxHeight: "100vh", maxWidth: "500px" }}>
                    <div>
                        <Card>
                            <Card.Body>
                                <h2 className="text-center mb-5">
                                    Nytt foto till galleriet
                                </h2>
                                <Form onSubmit={handleAddPhoto}>

                                    <Form.Group id="image" controlId="formFile">
                                        <div>
                                            <Form.Label>Foto</Form.Label>
                                        </div>
                                        <Form.Control
                                            className="mb-4"
                                            name="image"
                                            onChange={handleFileChange}
                                            type="file"
                                            required
                                            style={{width: "100%"}}
                                        />
                                    </Form.Group>

                                    <Button
                                        className="mt-5 w-100"
                                        type="submit"
                                    >
                                        Spara
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default withRouter(AddPhoto);