import React, { useEffect, useState, useContext } from "react";
import { withRouter, Redirect } from "react-router";
import { filterPosts } from "../utils";
import { Button } from "react-bootstrap";

import app from "../base";
import { useLocation, useHistory, useParams, Link } from "react-router-dom";
import { AuthContext } from "../Auth.js";

import { chooseClassName, formatDate } from "../utils";
import Header from "../Components/Header";

const db = app.database();

function ShowPost() {
    const location = useLocation();
    const history = useHistory();
    const [post, setPost] = useState();
    const [isLoading, setIsLoading] = useState();
    const { id } = useParams();
    const { currentUser } = useContext(AuthContext);

    const chooseBg = (category) => {
        if (category === "Jeppis Jazz Festival") return "jeppisJazzFestivalBg";
        if (category === "Jakob Big Band") return "jakobBigBandBg";
        if (category === "Thursday Jazz") return "thursdayJazzBg";
    };

    const chooseColor = (category) => {
        if (category === "Jeppis Jazz Festival")
            return "jeppisJazzFestivalColor";
        if (category === "Jakob Big Band") return "jakobBigBandColor";
        if (category === "Thursday Jazz") return "thursdayJazzColor";
    };

    const deletePost = () => {
        var userIsSure = window.confirm("Är du säker?");
        if (userIsSure) {
            const ref = db.ref("posts");
            ref.child(id).remove();
            history.push("/");
        }
    };

    

    useEffect(() => {
        setIsLoading(true);
        var postsArray;
        const ref = db.ref("posts");

        ref.child(id).on("value", (snapshot) => {
            setPost(snapshot.val());
            setIsLoading(false);
        });
    }, []);

    if (isLoading) return "Loading";

    return post ? (
        <>
            <Header />
            <div className="showPost">
                <div className="showPost-a">
                    <p
                        className={`showPostCategory ${chooseClassName(
                            post.category
                        )}`}
                    >
                        {post.category}
                    </p>
                    <h3 className="showPostTitle">
                        {post.title}{" "}
                        {currentUser && (
                            <Link to={`/editpost/${id}`}>
                                <i class="far fa-edit"></i>
                            </Link>
                        )}
                    </h3>
                    <p className="showPostInfoText">
                        <div className="iconWrapper">
                            {" "}
                            <i
                                className={`fas fa-calendar-alt ${chooseColor(
                                    post.category
                                )}`}
                            ></i>
                        </div>{" "}
                        {formatDate(post.date)}, kl. {post.time}
                    </p>
                    <p className="showPostInfoText">
                        <div className="iconWrapper">
                            <i
                                className={`fas fa-home ${chooseColor(
                                    post.category
                                )}`}
                            ></i>
                        </div>{" "}
                        {post.place}
                    </p>
                    {post.address && (
                        <p className="showPostInfoText">
                            <div className="iconWrapper">
                                <i
                                    className={`fas fa-map-marker-alt ${chooseColor(
                                        post.category
                                    )}`}
                                ></i>
                            </div>{" "}
                            {post.address}
                        </p>
                    )}
                    <p className="showPostInfoText">
                        <div className="iconWrapper">
                            <i
                                className={`fas fa-money-bill-wave ${chooseColor(
                                    post.category
                                )}`}
                            ></i>
                        </div>{" "}
                        {post.price}
                    </p>
                    {post.mediaLink && (
                        <p className="showPostInfoText">
                            <div className="iconWrapper">
                                <i
                                    className={`fab fa-youtube ${chooseColor(
                                        post.category
                                    )}`}
                                ></i>
                            </div>{" "}
                            <a className="showPostInfoLink" target="_blank" href={post.mediaLink}>
                                {post.mediaLink}
                            </a>
                        </p>
                    )}
                    {currentUser && (
                        <p className="showPostInfoText">
                            Author: {post.createdBy}
                        </p>
                    )}
                </div>
                <div className="showPost-b">
                    <img src={post.fileUrl} />
                    <h4 className="showPostBTitle">{post.title}</h4>
                    {post.description && (
                        <p className="showPostBDescription">
                            {post.description}
                        </p>
                    )}
                    {post.bandMembers &&
                        post.bandMembers.map((member) => (
                            <div className="bandMemberWrapper">
                                <span className="bandMemberName">
                                    {member.bandMemberName}
                                </span>
                                ,{" "}
                                <span className="bandMemberInstrument">
                                    {member.bandMemberInstrument}
                                </span>
                            </div>
                        ))}
                    {currentUser && (
                        <Button
                            onClick={deletePost}
                            variant="danger"
                            className="w-100 mt-5"
                            type="submit"
                        >
                            Radera evenemang
                        </Button>
                    )}
                </div>
            </div>
        </>
    ) : (
        ""
    );
}

// export default ShowPost
export default withRouter(ShowPost);
